<template>
  <form @submit.prevent="cancel">
    <modal-card :title="`Please tell us why you're cancelling`">
      <loading v-if="loading" />
      <b-field label="Reason*">
        <textarea-autosize
          v-model="reason"
          :max-height="200"
          :disabled="processing"
          placeholder="Please tell us why you're cancelling..."
          class="textarea"
          rows="3"
          autosize
        />
      </b-field>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="loading || processing || !validForm"
        type="submit"
        class="button is-danger"
      >
        Cancel
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "CancellationFeedbackModal",
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      reason: ""
    };
  },
  computed: {
    validForm() {
      return !this.$_.isEmpty(this.reason);
    },
    contractId() {
      return this.contract._id;
    }
  },
  methods: {
    cancel() {
      if (!this.validForm) return;

      this.processing = true;
      this.$store
        .dispatch("billing/cancelContract", {
          contractId: this.contractId,
          reason: this.reason
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: `${result}`
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
::v-deep .modal-card-head {
  background-color: $danger !important;
}
</style>
